"use client";

import { SearchIcon } from "@/components/icons";
import { clsx } from "@frend-digital/ui";
import { useId, useRef, type ComponentProps } from "react";
import { useFormContext } from "react-hook-form";
import { Text, type TextColorProps } from "../Text";
import styles from "./index.module.css";

interface InputProps extends React.ComponentProps<"input"> {
	label?: string;
	labelClassName?: string;
	labelColor?: TextColorProps;
	inputClassName?: string;
	icon?: React.ReactNode;
	error?: string | boolean;
	variant?: "default" | "search";
	onClear?: () => void;
}

export const Input = ({
	id: initialId,
	type,
	variant = "default",
	className,
	error,
	placeholder,
	label,
	labelClassName,
	labelColor,
	inputClassName,
	...props
}: InputProps) => {
	const internalId = useId();
	const id = initialId ?? internalId;
	const isSearch = variant === "search";
	const ref = useRef<HTMLInputElement>(null!);

	return (
		<label className={clsx(styles.label, className, error && styles.error)} htmlFor={id}>
			{label && (
				<Text
					variant="utility5"
					asChild
					color={labelColor ?? "secondary"}
					bold
					className={clsx(styles.labelText, labelClassName)}>
					<span>{label}</span>
				</Text>
			)}
			<div className={clsx(styles.input, isSearch && styles.search, inputClassName)}>
				<input
					ref={ref}
					type={type ?? (isSearch ? "search" : undefined)}
					id={id}
					placeholder={placeholder}
					{...props}
				/>

				{isSearch && (
					<button type="submit">
						<SearchIcon className={styles.searchIcon} />
					</button>
				)}
			</div>
			{error && typeof error === "string" && (
				<Text variant="utility5" asChild className={styles.errorText}>
					<span>{error}</span>
				</Text>
			)}
		</label>
	);
};

export const TextField = ({
	name,
	...props
}: ComponentProps<typeof Input> & { name: string }) => {
	const {
		register,
		formState: { errors },
	} = useFormContext();
	return <Input error={errors[name]?.message as string} {...props} {...register(name)} />;
};
